import { GltfObject } from './gltf_object.js'
import { objectsFromJsons } from './utils.js'
import { gltfAnimationChannel, InterpolationPath } from './channel.js'
import { gltfAnimationSampler } from './animation_sampler.js'
import { gltfInterpolator } from './interpolator.js'

class gltfAnimation extends GltfObject {
  constructor() {
    super()
    this.channels = []
    this.samplers = []
    this.name = ''

    // not gltf
    this.interpolators = []
    this.maxTime = 0
    this.disjointAnimations = []
  }

  fromJson(jsonAnimation) {
    super.fromJson(jsonAnimation)

    this.channels = objectsFromJsons(jsonAnimation.channels, gltfAnimationChannel)
    this.samplers = objectsFromJsons(jsonAnimation.samplers, gltfAnimationSampler)
    this.name = jsonAnimation.name

    if (this.channels === undefined) {
      console.error('No channel data found for skin')
      return
    }

    for (let i = 0; i < this.channels.length; ++i) {
      this.interpolators.push(new gltfInterpolator())
    }
  }

  // advance the animation, if totalTime is undefined, the animation is deactivated
  advance(gltf, totalTime) {
    if (this.channels === undefined) {
      return
    }

    if (this.maxTime == 0) {
      for (let i = 0; i < this.channels.length; ++i) {
        const channel = this.channels[i]
        const sampler = this.samplers[channel.sampler]
        const input = gltf.accessors[sampler.input].getDeinterlacedView(gltf)
        const max = input[input.length - 1]
        if (max > this.maxTime) {
          this.maxTime = max
        }
      }
    }

    for (let i = 0; i < this.interpolators.length; ++i) {
      const channel = this.channels[i]
      const sampler = this.samplers[channel.sampler]
      const interpolator = this.interpolators[i]

      const node = gltf.nodes[channel.target.node]

      switch (channel.target.path) {
        case InterpolationPath.TRANSLATION:
          node.applyTranslationAnimation(interpolator.interpolate(gltf, channel, sampler, totalTime, 3, this.maxTime))
          break
        case InterpolationPath.ROTATION:
          node.applyRotationAnimation(interpolator.interpolate(gltf, channel, sampler, totalTime, 4, this.maxTime))
          break
        case InterpolationPath.SCALE:
          node.applyScaleAnimation(interpolator.interpolate(gltf, channel, sampler, totalTime, 3, this.maxTime))
          break
        case InterpolationPath.WEIGHTS: {
          const mesh = gltf.meshes[node.mesh]
          mesh.weightsAnimated = interpolator.interpolate(
            gltf,
            channel,
            sampler,
            totalTime,
            mesh.weights.length,
            this.maxTime
          )
          break
        }
      }
    }
  }
}

export { gltfAnimation }
